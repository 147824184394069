/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// new TEST
const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:31fb8783-77c0-451f-980d-042ba7ddb38c",
  aws_cognito_region: "us-east-1",
  oauth: {},
  aws_appsync_graphqlEndpoint: "https://doex6ddbtvfthan6lnitigmhd4.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};
export default awsmobile;
