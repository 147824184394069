export const generateCart = /* GraphQL */ `
  mutation generateCart($input: CreateCartInput!) {
    generateCart(input: $input) {
      id
      price {
        currency
        delivery
        discount
        discountCode
        subTotal
        tip
        total
        transaction
      }
    }
  }
`;

export const insertOrder = /* GraphQL */ `
  mutation InsertOrder($input: CreateOrderInput!) {
    insertOrder(input: $input) {
      id
      cleverlynkId
      mattersForSubscription
      check
      newOrder
      cleverlynk {
        id
        name
        deliveryPrice {
          type
        }
        muStoreId
        yapayaOfficeId
        domiprimeSiteId
        rcPickingPointId
        geolocationInfo {
          location {
            lat
            lng
          }
        }
      }
      history {
        status
        date
      }
      note {
        note
        priority
      }
      fulfillmentType
      picapTrackingLink
      domiprimeId
      muTrackingLink
      picapId
      mensajerosId
      oficiosId
      rapiboyId
      yapayaId
      rappicargoId
      rappicargoTrackingLink
      deliveryInfo {
        deliveryAddress
        deliveryPropertyTypeInfo
        deliveryAdditionalInfo
        deliveryDate
        deliveryTime
        deliveryHourRange
        deliveryLocation {
          lat
          lng
        }
      }
      hidden
      identification
      mandatoryCriteria {
        label
        value
        criteriaType
      }
      optionalCriteria {
        label
        value
        criteriaType
      }
      paymentStatus
      paymentMethod
      price {
        subTotal
        delivery
        discount
        discountCode
        tip
        tax
        transaction
        total
        currency
      }
      status
      origin
      updatedAt
      createdAt
      bankTransferImage
      items {
        id
        name
        price
        quantity
        images
        posReference
        og_price
        sku
        subitems {
          id
          name
          type
        }
        chosenOptions {
          id
          subitemId
          posReference
          label
          value
          quantity
          subitemType
          input
        }
      }
    }
  }
`;

export const revertTransaction = id => `
  mutation revertTransaction{
    revertTransaction(orderId: "${id}")
  }
`;

export const setImageForOrder = (orderId: string, newURL: string) => `
  mutation MyMutation {
    setImageForOrder(newURL: "${newURL}", orderId: "${orderId}")
  }
`;
